<template>
  <div>
    <!-- 创建B2B空运订单 -->
    <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="popup"
		:lang="$i18n.locale"
    width="890px"
    :before-close="handleDialogClose"
    >
    	<el-form ref="createForm" :model="createForm" :inline="true" :rules="rules" style="min-height: 300px;"
    		label-width="150px">
    		<el-form-item :label="$t('commonInfo.deliveryCountry')" prop="destCountry">
    			<el-select v-model="createForm.destCountry"
    				:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryCountry')"
    				style="width: 590px;">
    				<el-option v-for="item in countryList" :key="item.value" :label="item.name" :value="item.value">
    				</el-option>
    			</el-select>
    		</el-form-item>
    		<el-form-item v-show="showOne" :label="$t('commonInfo.deliveryType')" prop="warehouseType">
    			<el-select v-model="createForm.warehouseType"
    				:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryType')"
    				style="width: 590px;">
    				<el-option v-for="item in warehouseTypeList" :key="item.value" :label="item.name"
    					:value="item.value"></el-option>
    			</el-select>
    		</el-form-item>
    		<!-- 自提不需要显示仓库和地址，默认飞坦海外仓，需要填写联系人和联系方式 2022-4-12 -->
    		<el-form-item v-if="showType" :label="$t('commonInfo.deliveryMode')" prop="deliveryType">
    			<el-radio v-model="createForm.deliveryType" label="1">{{$t('commonInfo.homeDeliveryService')}}</el-radio>
    			<el-radio v-model="createForm.deliveryType" label="2">{{$t('commonInfo.whSelfPickup')}}</el-radio>
    		</el-form-item>
    		<el-form-item v-if="returnShowDestWh()" :label="$t('commonInfo.deliveryWarehouse')" prop="destWhNo">
    			<el-select v-model="createForm.destWhNo" filterable
    				:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')"
    				style="width: 590px;">
    				<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
    					:value="item.dictValue"></el-option>
    			</el-select>
    		</el-form-item>
    		<div v-show="showThree">
    			<el-form-item v-if="returnShowOtherDest()" :label="$t('commonInfo.deliveryDest')" prop="otherDest">
    				<el-input style="width: 590px;" v-model="createForm.otherDest" :disabled="editOtherDest"
    					:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.deliveryDest')"></el-input>
    			</el-form-item>
    			<el-form-item v-if="createForm.destCountry == 'SA' && createForm.warehouseType == '5'"
    				:label="$t('commonInfo.city')" prop="destCity">
    				<el-select v-model="createForm.destCity" :disabled="editCity" filterable
    					:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.city')"
    					style="width: 590px;">
    					<el-option v-for="item in saCityList" :key="item.dictCode" :label="item.dictLabel"
    						:value="item.dictLabel"></el-option>
    				</el-select>
    			</el-form-item>
    			<el-form-item v-if="createForm.warehouseType == '1'" :label="$t('commonInfo.fbaNo')" prop="fbaNo">
    				<el-input v-model="createForm.fbaNo" :placeholder="$t('commonInfo.FBATips')" style="width: 215px">
    				</el-input>
    			</el-form-item>
    	
    			<el-form-item v-if="createForm.warehouseType == '1'" :label="$t('commonInfo.fbaNoCode')" prop="fbaNoCode">
    				<el-input v-model="createForm.fbaNoCode" style="width: 215px"
    					:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNoCode')"></el-input>
    			</el-form-item>
    			<!-- 预约送仓时间 -->
    			<el-form-item v-if="createForm.warehouseType == '2'" :label="$t('commonInfo.deliveryDate')"
    				prop="deliveryDate">
    				<el-date-picker clearable style="width: 590px;" v-model="createForm.deliveryDate"
    					:picker-options="deliveryPickerOptions" type="date" value-format="yyyy-MM-dd"
    					:placeholder="$t('chinaWhB2BOrderPage.deliveryDateTips')"></el-date-picker>
    			</el-form-item>
    			<!-- 预约信息 注意：该字段与上传PDF文件绑定，填写或者上传某一个，则另一个必填-->
    			<el-form-item v-if="createForm.warehouseType == '2'" :label="$t('commonInfo.asnNo')" prop="asnNo">
    				<el-input v-model="createForm.asnNo" style="width: 590px"
    					:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.asnNo')"></el-input>
    			</el-form-item>
    			<!-- 选择自定义仓库时 需要必填联系人和联系电话 -->
    			<el-form-item
    				:label="createForm.deliveryType=='2'?$t('commonInfo.consigneeTypeOne'):$t('commonInfo.contactMan')"
    				v-if="createForm.warehouseType == '5' && showContactInfo" prop="contacts">
    				<el-input v-model="createForm.contacts" style="width: 215px" :disabled="editContactInfo"
    					:placeholder="createForm.deliveryType=='2'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.consigneeTypeOne'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contactMan')">
    				</el-input>
    			</el-form-item>
    			<el-form-item
    				:label="createForm.deliveryType=='2'?$t('commonInfo.consigneeContact'):$t('commonInfo.contact')"
    				v-if="createForm.warehouseType == '5' && showContactInfo" prop="contactsTel">
    				<el-input v-model="createForm.contactsTel" style="width: 215px" :disabled="editContactInfo"
    					:placeholder="createForm.deliveryType=='2'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.consigneeContact'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contact')">
    				</el-input>
    			</el-form-item>
    			<el-form-item :label="$t('commonInfo.declaredValue')" prop="declaredValue">
    				<el-input v-model.number="createForm.declaredValue" style="width: 215px"
    					:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.declaredValue')">
    					<span slot="suffix" style="margin-right: 5px;">USD</span>
    				</el-input>
    			</el-form-item>
    			<!-- 包裹总件数和商品总件数公用一个字段 -->
    			<el-form-item
    				:label="createForm.warehouseType=='6'?$t('commonInfo.packageCount'):$t('commonInfo.skuCount')"
    				prop="skuCount">
    				<el-input v-model.number="createForm.skuCount"
    					:style="{'width': createForm.warehouseType == '1'?'185px':'215px'}"
    					:placeholder="createForm.warehouseType=='6'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageCount'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.skuCount')">
    				</el-input>
    				<!-- 选择FBA订单提示 -->
    				<el-tooltip v-if="createForm.warehouseType == '1'" class="item" effect="dark"
    					:content="$t('chinaWhB2BOrderPage.orderSkuCountTips')" placement="top-start">
    					<i style="font-size: 22px; margin-left: 10px; color: #c7924c;" class="el-icon-warning"></i>
    				</el-tooltip>
    			</el-form-item>
    			<!-- 是否报关退税 -->
    			<el-form-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')" prop="customsDeclarationFlag">
    				<el-radio style="margin-right: 40px;" :disabled="createForm.clientOperationType == '1'" v-model="createForm.customsDeclarationFlag" :label="true">
    					{{$t('chinaWhB2BOrderPage.officialCD')}}</el-radio>
    				<el-radio v-model="createForm.customsDeclarationFlag" :label="false">
    					{{$t('chinaWhB2BOrderPage.unofficialCD')}}</el-radio>
    			</el-form-item>
					<!-- 订单类型（客户清关方式） -->
					<el-form-item :label="$t('commonInfo.orderType')" prop="clientOperationType">
						<el-radio style="margin-right: 40px;" :disabled="createForm.customsDeclarationFlag" v-model="createForm.clientOperationType" label="1">
							{{$t('chinaWhB2BOrderPage.express')}}</el-radio>
						<el-radio v-model="createForm.clientOperationType" label="2">Cargo</el-radio>
					</el-form-item>
    			<!-- 客户订单号 必填 根据客户信息设置判断是否显示 -->
    			<el-form-item v-if="isSpecial" :label="$t('chinaWhB2BOrderPage.clientOrderNo')"
    				prop="clientOrderNo">
    				<el-input v-model="createForm.clientOrderNo" style="width: 215px"
    					:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.clientOrderNo')">
    				</el-input>
    			</el-form-item>
    			<el-form-item :label="$t('chinaWhB2BOrderPage.kindnameDes')">
    				<el-select v-model="kindName" value-key="id" multiple filterable
    					:placeholder="$t('chinaWhB2BOrderPage.kindnameSelectTips')" style="width: 470px;">
    					<el-option v-for="item in kindNameList" :key="item.id" :label="item.label"
    						:value="item.value">
    						<span style="float: left">{{ item.productChineseDesc }}</span>
    						<span
    							style="float: right; margin-right: 20px; color: #8492a6; font-size: 13px">{{ item.productEnglishDesc }}</span>
    					</el-option>
    				</el-select>
    			</el-form-item>
    			<el-form-item>
    				<el-button type="primary" size="small" @click="openKindName" style="margin-left: 10px;"
    					icon="el-icon-plus">
    					{{$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')}}
    				</el-button>
    			</el-form-item>
    		</div>
    	</el-form>
    	<el-card v-show="showThree" class="box-card" v-loading="caseLoading">
    		<div slot="header" class="clearfix">
    			<span>{{$t('chinaWhB2BOrderPage.clientOrderPackageAmount')}}</span>
    			<div style="display: inline-block;" v-if="optType == 'add'">
    				<el-input v-model.number="caseNum" style="width: 120px; margin-left: 20px;" type="tel"
    					@input="handleNum()"></el-input>
    				<span
    					style="margin-left: 10px; color: #bdbdbd;">{{$t('chinaWhB2BOrderPage.createBoxNoTips')}}</span>
    			</div>
    			<span style="margin-left: 10px;" v-else>{{caseNum}}</span>
    		</div>
    		<!-- 超过十条数据隐藏 -->
    		<el-collapse v-show="caseNum > 0" v-model="showCaseDetail">
    			<el-collapse-item :title="$t('chinaWhB2BOrderPage.generated') + caseNum + $t('chinaWhB2BOrderPage.boxes') + ',' + $t('chinaWhB2BOrderPage.expandAndHide')" name="1">
    				<div class="productRow" v-if="isCaseNormal">
    					<el-row style="margin: 20px 0;" v-for="(item, index) in caseData" :key="index">
    						<el-col style="width: 107px;margin-right: 12px;">
    							<span
    								style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
    						</el-col>
    						<el-col :span="10">
    							<el-input v-model="item.input" style="font-size: 16px;" disabled />
    						</el-col>
    						<el-col :span="8" v-if="optType == 'edit'">
    							<el-button v-show="index == (caseData.length - 1)" size="medium" type="text"
    								icon="el-icon-circle-plus"
    								style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
    								@click="addCaseItem(item)" />
    							<el-button size="medium" type="text" icon="el-icon-delete"
    								style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
    								@click="delCaseItem(item,index)" />
    						</el-col>
    					</el-row>
    				</div>
    				<div v-if="isCaseSpecial">
    					<el-row class="productRow" v-for="(item,index) in caseDataCustom" :key="index"
    						:style="{padding: '10px 0',backgroundColor:item.color}">
    						<el-col style="width: 107px;margin-right: 12px;">
    							<span
    								style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
    						</el-col>
    			 		<el-col :span="12">
    							<span
    								style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;font-size: 16px;">{{item.preNum}}</span>
    							<el-input v-model="item.input" maxlength="20" @input="handleInputCustomCase()"
    								style="font-size: 16px;width: 200px;">
    								<template slot="suffix">
    									<el-tooltip v-if="item.input != ''" class="item" effect="dark"
    										:content="$t('chinaWhB2BOrderPage.caseAutoFillTips')"
    										placement="top-start">
    										<i class="el-input__icon el-icon-document"
    											@click="autoFillCaseInfo(item,index)"></i>
    									</el-tooltip>
    								</template>
    							</el-input>
    							<span
    								style="display: inline-block;height: 40px; text-align: right;padding: 0 0 0 12px; line-height: 40px;font-size: 16px;">{{item.nextNum}}</span>
    						</el-col>
    						<el-col :span="6" v-if="optType == 'edit'">
    							<el-button v-show="index == (caseDataCustom.length - 1)" size="medium" type="text"
    								icon="el-icon-circle-plus"
    								style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
    								@click="addCustomCaseItem(item)" />
    							<el-button size="medium" type="text" icon="el-icon-delete"
    								style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
    								@click="delCaseCustomItem(item,index)" />
    						</el-col>
    					</el-row>
    				</div>
    			</el-collapse-item>
    		</el-collapse>
    	
    	</el-card>
    	<div v-show="showThree" style="margin: 10px 0;height: 60px;">
    		<SingleUploadPDF v-model="fbxFlienName" v-show="createForm.warehouseType == '1'"
    			:tipText="$t('commonInfo.fbxFileTips')" :preFile="fbxPreName" @input="getFBXInfo"
    			class="fileUploadBtn">
    		</SingleUploadPDF>
    		<!-- 上传PDF附件 选择noon显示 -->
    		<SingleUploadPDF v-show="createForm.warehouseType == '2'" v-model="pdfFlienName"
    			:tipText="$t('commonInfo.pdfFileTips')" :preFile="pdfPreName" @input="getPDFInfo"
    			class="fileUploadBtn">
    		</SingleUploadPDF>
    		<!-- 上传清单附件 -->
    		<SingleUpload v-show="createForm.warehouseType != '6'" v-model="filename" @input="getExcel"
    			:tipText="$t('commonInfo.skuFileTips')" :preFile="fileTXTName" :orderNo="createForm.orderNo"
    			class="fileUploadBtn"></SingleUpload>
    		<!-- 模板文件下载，分类型 -->
    		<el-button size="small" class="fileUploadBtn" icon="el-icon-download" @click="handleDownloadAirTemplate">
    			{{$t('commonInfo.downloadTemplate')}}</el-button>
    		<!-- 上传分拣送货清单附件 -->
    		<SingleUpload v-model="sortingFilename" v-show="isSorting" @input="getSortingExcel"
    			:tipText="$t('commonInfo.sortSkuFileTips')" :preFile="sorttingFileTXTName" :orderNo="createForm.orderNo"
    			class="fileUploadBtn"></SingleUpload>
				<!-- 正式报关上传报关文件，非必填 文件类型不明确-->
				<MutipleUploadNoLimit
				  v-if="createForm.customsDeclarationFlag"
				  :tipText="$t('chinaWhB2BOrderPage.customsDeclarationFile')"
					:isClearList.sync="clearFileListFlag"
				  @input="getCustomsDeclaration"
				  class="fileUploadBtn"
				></MutipleUploadNoLimit>
    	</div>
    	<div slot="footer" class="dialog-footer">
    		<el-button @click="handleDialogClose" :disabled="submitLoading">{{$t('commonInfo.dialogBtnCancel')}}
    		</el-button>
    		<el-button type="primary" :loading="submitLoading" style="margin: 0 10px;" :disabled="submitLoading" @click="checkIsLastOrderNo">
    			{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
    		</el-button>
    	</div>
    </el-dialog>
		
		<!-- 添加品名对话框 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')" :visible.sync="kindNamePopup"
			append-to-body width="1000px" :lang="$i18n.locale" :close-on-click-modal="false" @close="closeDialog" v-loading="loading">
			<el-form ref="kindNameForm" :model="kindNameForm" label-width="180px">
				<el-row class="productRow" v-for="(item, index) in addKindNameList" :key="index" :gutter="4">
					<el-col :span="10">
						<el-form-item
							:label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'">
							<el-input v-model="item.productChineseDesc"
								:placeholder="$t('tipsInfo.inputPlaceholder') + ' ' + $t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item
							:label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageEn') +')'">
							<el-input v-model="item.productEnglishDesc" @keyup.enter.native="addItem()"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.dialogAddKindname') + ' ' + '(' + $t('commonInfo.languageEn') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="2">
						<el-button size="medium" type="text" icon="el-icon-circle-plus" style="margin: 0 5px;"
							@click="addItem()" />
						<el-button size="medium" type="text" icon="el-icon-delete" @click="delItem(index)" />
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button :disabled="addKindNameLoading" @click="closeDialog">{{$t('commonInfo.dialogBtnCancel')}}
				</el-button>
				<el-button :disabled="addKindNameLoading" :loading="addKindNameLoading" type="primary"
					@click="submitNewKindName" style="margin:0 10px;">{{$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		
		<!-- 创建修改订单成功显示面单信息 -->
		<el-dialog :title="$t('commonInfo.orderInfo')" :lang="$i18n.locale" :visible.sync="detailPopup" width="620px" append-to-body
			:close-on-click-modal="false" :before-close="handleCloseDetail">
			<div class="stepthrees">
				<div class="stepthree">
					<div class="three">
						<span>
							{{$t('commonInfo.orderNoColumn') + ':'}}
							<span
								style="font-size: 18px; color: red">{{ detail_orderNo }}</span>{{'，' + $t('commonInfo.total')}}
							<span style="font-size: 18px; color: red">{{orderCaseNum}}</span>
							{{$t('commonInfo.case')}}</span>
						<span>{{$t('commonInfo.deliveryCountry') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destCountry || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryWarehouse') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destWhNo == ""?$t('commonInfo.customeWarehouse'):detail_destWhNo || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryDest') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_otherDest || "------"}}</span></span>
						<span>{{$t('commonInfo.declaredValue') + '(USD)：'}}<span
								style="font-size: 18px; color: red">{{detail_declaredValue}}</span></span>
						<span>{{$t('chinaWhB2BOrderPage.szDlvdWarehouseAddress') + ':'}}
							<ul style="list-style: none">
								<li style="color: red; font-size: 18px">{{whAddress}}</li>
								<li>
									{{$t('commonInfo.contactMan') + ':' + whContactMan}}
									<span>({{userName}})</span>
								</li>
								<li>{{$t('commonInfo.contact') + ':' + whContactPhone}}</li>
							</ul>
						</span>
						<el-row v-show="createErrorInfo != ''">
							<span style="font-size: 12px;color: gray;"><i style="margin-right: 5px;"
									class="el-icon-warning"></i>{{createErrorInfo}}</span>
						</el-row>
						<el-row class="table-grid-content">
							<el-col v-show="frontOrafter == '1'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="primary" v-if="PdfLoading" :loading="PdfLoading">
									{{$t('tipsInfo.downloadTips')}}</el-button>
								<el-button v-else type="primary" class="btn-right" @click="getPdf"
									:disabled="PdfLoading">
									{{$t('commonInfo.downloadBoxStickers')}}
								</el-button>
							</el-col>
							<el-col v-show="frontOrafter == '0'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="info" class="btn-right" :loading="true">
									{{$t('chinaWhB2BOrderPage.orderReviewTips')}}
								</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</el-dialog>
		
  </div>

</template>

<script>
  import SingleUploadPDF from "@/components/upload/singleUploadPDF";
  import SingleUpload from "@/components/upload/singleUpload";
	import MutipleUploadNoLimit from "@/components/upload/mutipleUploadNoLimit";
	import {
		validNumberToFix2,
		validPositiveInteger
	} from '@/utils/validate' //验证数字
  export default {
    name: 'CreateOrder',
    components: {
      SingleUpload,
			SingleUploadPDF,
			MutipleUploadNoLimit
    },
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowData:{
        type:Object,
        default:function(){
        	return {};
        }
      },
      optType:{
        type:String,
        default:function(){
          return '';
        }
      }
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
      		this.popup = newValue;
          if(newValue){
            if(this.optType == 'add'){
              this.title = this.$t('commonInfo.createAirOrder');
							this.getOrder();
            }else{
              this.title = this.$t('commonInfo.modifyAirOrder');
							this.createForm.orderNo = this.rowData.esnadTobOrderNo;
							this.getOrderInfo(this.rowData.esnadTobOrderNo);
            }
            
						let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
						this.prefixAwbNo = customInfo.prefixAwbNo;
						this.userName = customInfo.clientShortName;
						this.frontOrafter = customInfo.frontOrafter;
						this.clientEnName = customInfo.clientEnName;
						this.isSpecial = customInfo.isSpecial;
						this.getKindNameList();
						this.getDict();
          }
      	},
      	immediate: true,
      },
			
			'createForm.destCountry': {
				immediate: true,
				handler(val) {
					this.showTwo = false;
					this.showThree = false;
					this.createForm.warehouseType = '';
					this.warehouseTypeList = [];
					if (val != '' && val != null) {
						// 获取当前客户快递报价信息
						this.getClientPrice(val);
						if (val == 'SA') { //沙特
							this.warehouseTypeList = [{
								name: "FBA",
								value: '1',
							}, {
								name: "NOON",
								value: '2',
							}, {
								name: "其他海外仓/私人地址",
								value: '5',
							}]
							this.createForm.destCountryId = 2;
							this.isSa = '1';
						} else {
							this.showType = false;
							this.warehouseTypeList = [{
								name: "FBA",
								value: '1',
							}, {
								name: "NOON",
								value: '2',
							}, {
								name: "FBC(阿联酋-飞坦海外仓)/私人地址",
								value: '5',
							}]
							this.createForm.destCountryId = 3;
							this.isSa = '2';
						}
						this.showOne = true;
					} else {
						this.showOne = false;
					}
				}
			},
			'createForm.warehouseType': {
				immediate: true,
				handler(val) {
					this.selectWhList = [];
					this.createForm.deliveryType = '';
					//修改不清空
					if(this.optType == 'add'){
						this.caseNum = 0;
						this.caseData = [];
						this.caseDataCustom = [];
					}
					
					if (val != '' && val != null) {
						this.getWhDict(val); //获取仓库信息
						if (val == '5' && this.createForm.destCountry == 'SA') {
							this.showType = true;
						} else {
							this.showType = false;
						}
						if (val == '6') { // 转单小包
							this.getSaInfo();
							this.showThree = true;
						}
						//console.log('ss' + val);
						this.showTwo = true;
					} else {
						this.showTwo = false;
						this.showThree = false;
					}
					this.createForm.destWhNo = '';
					this.createForm.otherDest = '';
				}
			},
			'createForm.deliveryType': {
				immediate: true,
				handler(val) {
					this.createForm.contacts = '';
					this.createForm.contactsTel = '';
					if (val == '1') {
						if (this.selectWhList.length > 0) {
							this.createForm.destWhNo = this.selectWhList[0].dictLabel
						}
						this.editCity = false;
						this.createForm.destCity = '';
						this.showTwo = true;
						this.showThree = true;
					} else if (val == '2') {
						this.editCity = true;
						let list = this.saCityList.filter(item => item.dictValue == '1');
						this.createForm.destCity = list[0].dictLabel;
						this.getSaInfo();
						this.showThree = true;
					}
					// this.createForm.destWhNo = '';
					// this.createForm.otherDest = '';
				}
			},
			'createForm.destWhNo': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						this.clearInfo();
						this.getDestInfo(val);
						if (val == 'FBC(阿联酋-飞坦海外仓)') { // 为该海外仓则不显示联系信息
							this.showContactInfo = false;
						} else {
							this.showContactInfo = true;
						}
			
						this.showThree = true;
					} else {
						if (this.createForm.warehouseType != '6') {
							this.showThree = false;
						} else {
							this.showThree = true;
						}
			
					}
			
				}
			},
			'createForm.customsDeclarationFlag':{
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						// 如果为正式报关，则不能选择快递
						if(val && this.createForm.clientOperationType == '1'){
							this.createForm.clientOperationType = '2';
						}
					}
				}
			},
			'createForm.clientOperationType':{
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						// 如果为快递，则不能选择正式报关
						if(val == '1' && this.createForm.customsDeclarationFlag){
							this.createForm.customsDeclarationFlag = false;
						}
					}
				}
			}

    },
    data() {
      return {
        popup:false,
        title:'',

        submitLoading:false,
				loading:false,
        
				
        createForm:{
          destCountry: '', //国家
          destCity: '', //城市
          destCountryId: 2,
          warehouseType: '',
          destWhNo: '', //仓库
          otherDest: '', //地址
          fbaNo: '', //FBA号
          fbaNoCode: '', //FBA跟踪编码
          asnNo: '', //noon流程的预约号
          deliveryDate: '', //预约送仓时间
          orderNo: '', //订单编号
          contacts: '', //自定义海外仓 联系人
          contactsTel: '', //自定义海外仓 联系电话
          declaredValue: 0, //申报金额
          skuCount: 0, //SKU总数
          deliveryType: '', //派送方式，1送货上门，2仓库自提
          execllist: [], //转单小包信息
          customsDeclarationFlag: false, //是否报关退税 默认false
					customsDeclarationFileUrlList:[],//正式报关文件
          clientOrderNo: '', //客户订单号，必填
					clientOperationType:'',//订单类型（客户清关方式）： 1、快递 2、Cargo
        },
				
				// 空运清单模板
				airTemplate: {
					fba: '',
					noon: '',
					owh: '',
				},
				countryList: [{ //国家选项信息
					name: "Saudi Arabia(沙特)",
					value: "SA"
				}, {
					name: "United Arab Emirates(阿联酋)",
					value: "AE"
				}],
				// 1、FBA，2、NOON，3、Fleetan海外仓（中转FBN/FBA），4、Fullfillment，5其他海外仓/私人地址 6、转单小包
				warehouseTypeList: [],
				isSa: '', //是否选择沙特 1沙特 2阿联酋
				selectWhList: [],
				isHandleSubmit: false, //是否点击提交
				saCityList:[],
				
				// 预约送仓时间限制选择范围
				deliveryPickerOptions: { // 限制送仓时间的选择范围
					disabledDate(time) {
						let nowDate = Date.now();
						nowDate = nowDate - 24 * 60 * 60 * 1000; //可选当天
						let nowDay = new Date(time).getDay(); //周几
						// 超过当前时间的日期不可选
						return nowDate > time.getTime() ||
							nowDay == 5
					},
				},
				
				//需要分层显示
				showOne: false, //显示第一层
				showType: false, // 显示派送方式
				showContactInfo: true, //显示联系人和联系电话信息
				showTwo: false, //显示第二层
				showThree: false, //显示第三层
				formData: [], //第二次提交参数
				caseNum: 1, //箱数，可以自动生成箱号
				showCaseDetail: '1',
				fileList: [],
				headers: {},
				imgList: [], //预览POD
				showImg: false,
				uploadData: [], //上传报错返回的数据
				uploadSuccess: false,
				dialogErrorVisible: false, //显示上传报错数据窗口
				uploadloading: false,
				prefixAwbNo: '', //客户五位标识码
				userName:'',
				frontOrafter:'1',
				//箱号生成
				caseLoading: false,
				isSpecial: false, //该客户是否自定义填写箱号信息
				clientEnName: '', //客户的英文简称
				isCaseSpecial: false, //是否显示客户自定义填写箱号
				isCaseNormal: true, //是否显示正常的箱号信息
				orderCaseNum: 0, //订单箱数
				caseData: [],
				caseDataCustom: [], //显示自定义海外仓的箱号数据
				timeout: null, //防抖定时器
				
				//是否可以编辑信息
				editContactInfo: false,
				editOtherDest: false,
				editCity: false,
				// 自提信息
				SelfDestWhNo: '',
				SelfOtherDest: '',
				SelfDestCity:'',
				
				// 附件上传
				fbxFlienName: '',
				fbxPreName: [],
				fbxLink: '',
				pdfFlienName: '', //上传PDF文件名称
				pdfLink: '', //上传PDF路径
				pdfPreName: [], //pdf上传的预览文件名集合
				sortingFilename: "", //分拣清单附件名称
				sorttingFileTXTName: [], //已上传清单附件名称集合
				sortingSkuLink: "", //分拣清单附件链接
				isSorting: false, //是否需要分拣清单 前提条件为noon订单
				filename: '', //SKU文件名
				fileTXTName: [], // 已上传文件集合，通过接口获取文件名和地址url，回传给子组件upload显示该订单已上传的文件列表
				skuListLink: '', //上传SKU文件路径
				clearFileListFlag:false,//清空预览
				
				
				//品名
				kindName: [],
				kindNamePopup: false,
				kindNameList: [{ //常用品名
						id: 'common1',
						value: '耳机',
						label: '耳机',
						productChineseDesc: '耳机',
						productEnglishDesc: 'earphone'
					}, {
						id: 'common2',
						value: '手机壳',
						label: '手机壳',
						productChineseDesc: '手机壳',
						productEnglishDesc: 'phone shell'
					}, {
						id: 'common3',
						value: '平板壳',
						label: '平板壳',
						productChineseDesc: '平板壳',
						productEnglishDesc: 'tablet shell'
					}, {
						id: 'common4',
						value: '墙纸',
						label: '墙纸',
						productChineseDesc: '墙纸',
						productEnglishDesc: 'wall paper'
					}, {
						id: 'common5',
						value: '玩具',
						label: '玩具',
						productChineseDesc: '玩具',
						productEnglishDesc: 'toy'
					}, {
						id: 'common6',
						value: '理发器',
						label: '理发器',
						productChineseDesc: '理发器',
						productEnglishDesc: 'haircut machine'
					}, {
						id: 'common7',
						value: '衣服',
						label: '衣服',
						productChineseDesc: '衣服',
						productEnglishDesc: 'clothes'
					}, {
						id: 'common8',
						value: '鼠标套装',
						label: '鼠标套装',
						productChineseDesc: '鼠标套装',
						productEnglishDesc: 'Mouse coordinates'
					},
					{
						id: 'common9',
						value: '咖啡压粉锤',
						label: '咖啡压粉锤',
						productChineseDesc: '咖啡压粉锤',
						productEnglishDesc: 'Coffee Tampers'
					},
					{
						id: 'common10',
						value: '收纳包',
						label: '收纳包',
						productChineseDesc: '收纳包',
						productEnglishDesc: 'Storage bag'
					},
				], 
				//总的品名集合
				addKindNameList: [], //新增品名集合
				addKindNameLoading: false,
				kindNameForm: {},
				
				//订单详情窗口
				detailPopup: false,
				PdfLoading: false,
				pdfUrl:'',
				createErrorInfo:'',
				detail_orderNo: '',
				detail_destCountry: '',
				detail_destWhNo: '',
				detail_otherDest: '',
				detail_declaredValue: '',
				whAddress: '东莞市塘厦镇桥蛟中路1号好运童园科技园8栋--飞坦数字物流', //深圳仓库地址
				whContactMan: '', //仓库联系人
				whContactPhone: '', //仓库联系电话
				
				rowOrderNo: '',
				dlvdMainDeliveryTime: '',
      }
    },
		//监听属性 类似于data概念
		computed: {
			rules() { // 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
				//自定义验证规则 只能填写数字或者英文字母
				const validateCode = (rule, value, callback) => {
					let reg = /[^\w\.\/]/ig
					if (reg.test(value)) {
						callback(new Error(this.$t('commonInfo.numberAndLetterLimit')))
				  } else {
						callback()
					}
				};
				return {
					destCountry: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.country'),
						trigger: 'blur'
					}],
					warehouseType: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryType'),
						trigger: 'blur'
					}],
					destWhNo: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryWarehouse'),
						trigger: 'blur'
					}],
					otherDest: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDest'),
						trigger: 'blur'
					}],
					fbaNo: [{
							required: true,
							message: this.$t('commonInfo.FBAExample'),
							trigger: 'blur'
						},
						{
							min: 12,
							max: 12,
							message: this.$t('commonInfo.fbaNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					fbaNoCode: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.fbaNoCode'),
							trigger: 'blur'
						},
						{
							min: 8,
							max: 8,
							message: this.$t('commonInfo.fbaNoCodeLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
						 trigger: 'blur',
						}
					],
					asnNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.asnNo'),
						trigger: 'blur'
					}, {
						validator: validateCode,
						trigger: 'blur',
					}],
					deliveryDate: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.deliveryDateTips'),
						trigger: 'blur'
					}],
					contacts: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contactMan'),
						trigger: 'blur'
					}],
					contactsTel: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contact'),
						trigger: 'blur'
					}],
					skuCount: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.skuCount'),
						trigger: 'blur'
					}, {
						validator: validPositiveInteger,
						trigger: 'blur'
					}],
					declaredValue: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.declaredValue'),
						trigger: 'blur'
					}, {
						validator: validNumberToFix2,
						trigger: 'blur'
					}],
					customsDeclarationFlag: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.customsDeclaration'),
						trigger: 'blur'
					}],
					clientOperationType:[
						{
							required: true,
							message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
								'chinaWhB2BOrderPage.express'),
							trigger: 'blur'
						}
					],
					destCity: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.ctiy'),
						trigger: 'blur'
					}],
					clientOrderNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.clientOrderNo'),
						trigger: 'blur'
					}]
				}
			},
		},
    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
				this.showType = false; //隐藏派送方式
				this.pdfUrl = '';
        this.createForm = {
          destCountry: '', //国家
          destCity: '', //城市
          destCountryId: 2,
          warehouseType: '',
          destWhNo: '', //仓库
          otherDest: '', //地址
          fbaNo: '', //FBA号
          fbaNoCode: '', //FBA跟踪编码
          asnNo: '', //noon流程的预约号
          deliveryDate: '', //预约送仓时间
          orderNo: '', //订单编号
          contacts: '', //自定义海外仓 联系人
          contactsTel: '', //自定义海外仓 联系电话
          declaredValue: 0, //申报金额
          skuCount: 0, //SKU总数
          deliveryType: '', //派送方式，1送货上门，2仓库自提
          execllist: [], //转单小包信息
          customsDeclarationFlag: false, //是否报关退税 默认false
					customsDeclarationFileUrlList:[],//正式报关文件
          clientOrderNo: '', //客户订单号，必填
					clientOperationType:'',//订单类型（客户清关方式）： 1、快递 2、Cargo
        };
				
        this.$parent.rowData = {};
        this.$parent.optType = '';
        this.$parent.createOrderPopup = false;
      },
			// 获取字典数据
			async getDict() {
				// 获取模板
				const res = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_template_url'
					}
				});
			
				if (res.code === 200) {
					let that = this;
					let data = res.rows;
					data.forEach(item => {
						if (item.dictLabel == 'FBA清单附件模板') {
							that.airTemplate.fba = item.remark;
						}
						if (item.dictLabel == 'NOON清单附件模板') {
							that.airTemplate.noon = item.remark;
						}
						if (item.dictLabel == '海外仓清单附件模板') {
							that.airTemplate.owh = item.remark;
						}
					})
				} else {
					this.$message.error(res.msg);
				}
				// 获取B2B空运城市地址 sys_b2bAirCity_list
				const cityRes = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_b2bAirCity_list'
					}
				});
				if (cityRes.code === 200) {
					this.saCityList = cityRes.rows;
				} else {
					this.$message.error(cityRes.msg);
				}
			
			},
			// 获取订单号
			async getOrder() {
				const res = await this.$http.post("/toborder/create/0");
				if (res.code === 200) {
					this.createForm.orderNo = res.data;
				} else {
					this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
				}
			},
			//去重数组，以中文描述为准
			quchong(arr) {
				const res = new Map();
				return arr.filter(
					(list) => !res.has(list.productChineseDesc) && res.set(list.productChineseDesc, 1)
				);
			},
			// 获取当前用户快递的报价信息
			async getClientPrice(val){
				let countryId = val == 'SA'?'2':'3';
				//获取字典的飞坦海外仓信息
				await this.$http.get("/toborder/priceList").then(res => {
					if (res.code == 200) {
						
					}
				})
			},
			//自提则填充飞坦海外仓信息 只填充仓库和地址
			async getSaInfo() {
				//获取字典的飞坦海外仓信息
				await this.$http.get("/toborder/system/dict/data/list?dictType=sys_SA_FullfillmentWh").then(res => {
					if (res.code == 200) {
						// 现在备注包含详细地址、联系方式和城市信息
						let remarkInfo = res.rows[0].remark;
						this.SelfOtherDest = remarkInfo.split('###')[0];
						if (this.createForm.warehouseType == '6') {
							this.SelfDestWhNo = res.rows[0].dictLabel;
						} else {
							this.SelfDestWhNo = '自提/Self Pickup';
							this.editContactInfo = false;
						}
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			//返回是否显示仓库信息
			returnShowDestWh() {
				let isShow = true;
				if (this.createForm.destCountry != '' && this.createForm.destCountry != 'AE') {
					if (this.showTwo) {
						if (this.createForm.warehouseType == '5') { //排除FBA NOON
							if (this.createForm.deliveryType == '2' || this.createForm.deliveryType == '') { // 自提不显示
								isShow = false;
							}
						}
						if (this.createForm.warehouseType == '6') { // 转单小包不需要显示，直接填充飞坦海外仓信息
							isShow = false;
						}
					} else { //排除按层级显示
						isShow = false;
					}
				} else if (this.createForm.destCountry == 'AE') {
					isShow = true;
				} else {
					isShow = false;
				}
				return isShow;
			},
			// 返回是否显示仓库地址信息
			returnShowOtherDest() {
				let isShow = true;
				if (this.createForm.destCountry == 'SA') {
					if (this.createForm.warehouseType == '5') { //排除FBA NOON
						if (this.createForm.deliveryType == '2' || this.createForm.deliveryType == '') { // 自提不显示
							isShow = false;
						}
					}
					if (this.createForm.warehouseType == '6') { // 转单小包不需要显示，直接填充飞坦海外仓信息
						isShow = false;
					}
				} else if (this.createForm.destCountry == 'AE') {
					if (this.createForm.warehouseType == '') {
						isShow = false;
					}
				} else {
					isShow = false;
				}
				return isShow;
			},
			//获取仓库数据
			async getWhDict(type) {
				let dictText = '';
				switch (type) {
					case '1':
						dictText = this.isSa == '1' ? 'sys_SA_FBAWh' : 'sys_AE_FBAWh';
						this.fileTip = 'FBA外箱箱唛';
						break;
					case '2':
						dictText = this.isSa == '1' ? 'sys_SA_NOONWh' : 'sys_AE_NOONWh';
						this.fileTip = 'NOON的送仓文件';
						break;
					case '3':
						dictText = 'sys_SA_FleetanWh';
						break;
					case '4':
						dictText = 'sys_SA_FullfillmentWh';
						break;
					case '5':
						dictText = this.isSa == '1' ? 'sys_SA_OtherWh' : 'sys_AE_OtherWh';
						break;
				}
				await this.$http.get("/toborder/system/dict/data/list?dictType=" + dictText).then(res => {
					if (res.code == 200) {
						this.selectWhList = res.rows;
						if (this.optType == 'add') {
						}
					} else {}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			//选择仓库时自定填充仓库地址信息
			getDestInfo(val) {
				console.log(val);
				let that = this;
				that.createForm.otherDest = '';
				that.createForm.contacts = '';
				that.createForm.contactsTel = '';
				that.editOtherDest = false;
				that.editContactInfo = false;
				setTimeout(function() {
					that.selectWhList.forEach(item => {
						if (item.dictValue == val) {
							// 获取仓库的城市信息
							let whCity = '',whContact = '';
							if(item.remark && item.remark.includes('&&&')){
								whCity = item.remark.split('&&&')[1];
								whContact = item.remark.split('&&&')[0];
							}else{
								whCity = '';
								whContact = item.remark;
							}
							
							if (that.createForm.warehouseType == '5') { //如果是海外仓，则获取字典存储的地址###联系人###联系电话信息
								// 自定义海外仓可编辑，其他私人地址不可编辑
								if (val != 'custom') {
									if (item.remark != '' && item.remark != null) {
										// 现在备注包含地址信息、联系信息、城市信息
										let info = whContact.split('###');
										that.createForm.otherDest = info[0];
										that.createForm.contacts = info[1];
										that.createForm.contactsTel = info[2];
										if (that.createForm.contacts == 'Fleetan-') {
											that.createForm.contacts = that.createForm.contacts + that.prefixAwbNo;
										}
										that.editOtherDest = true; //不可编辑地址，直接填充
										that.editContactInfo = true; //不可编辑联系人联系方式
										that.editCity = true; //不是自定义海外仓不可修改城市(默认RUH)
			
										// 沙特海外仓仓库默认都在利雅得城市
										// 2025-03-10 @zpy 所有派送地址需要城市信息，可以从仓库字典获取城市信息
										// if (that.isSa == '1') {
										// 	let list = that.saCityList.filter(item => item.dictValue ==
										// 		'1');
										// 	that.createForm.destCity = list[0].dictLabel;
										// } else { // 阿联酋暂时没有仓库，城市默认为空
										// 	that.createForm.destCity = '';
										// }
										that.createForm.destCity = whCity;
									}
								} else {
									// 沙特自定义海外仓可以选择城市信息
									that.editCity = false;
									that.createForm.destCity = '';
								}
							} else { // FBA NOON
								that.createForm.otherDest = whContact;
								that.editOtherDest = true; //不可编辑地址，直接填充
								// 选择FBA和NOON 暂时不上传城市信息
								// 2025-03-10 @zpy FBA和NOON需要城市信息
								that.createForm.destCity = whCity;
							}
						}
					})
					that.loading = false;
				}, 300)
			},
			clearInfo() {
				this.formData = [];
				
				this.createForm.fbaNo = '';
				this.createForm.fbaNoCode = '';
				this.createForm.asnNo = '';
				this.createForm.contacts = '';
				this.createForm.contactsTel = '';
			
				this.fbxLink = ''; //FBX上传文件路径
				this.fbxPreName = [];
				this.pdfLink = ''; //PDF上传文件路径
				this.pdfPreName = [];
				this.skuListLink = '';
				this.sortingSkuLink = '';
				this.fileTXTName = [];
				this.sorttingFileTXTName = [];
				this.isSorting = false;
				this.uploadSuccess = false;
				this.isHandleSubmit = false;
				// 修改订单不清空
				if(this.optType == 'add'){
					this.caseNum = 0;
					this.caseData = [];
					this.caseDataCustom = [];
				}
			},
			
			// 上传文件
			getExcel(data) {
				this.skuListLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: data,
					}]
					//如果是noon订单，则显示分拣送货清单附件按钮
					if (this.createForm.warehouseType == "2") {
						this.isSorting = true;
					} else {
						this.isSorting = false;
					}
				} else {
					this.isSorting = false;
				}
			},
			
			//获取上传FBA返回的路径
			getFBXInfo(data) {
				this.fbxLink = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.fbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			getPDFInfo(data) {
				this.pdfLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.pdfPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			// 获取上传分拣送货清单附件
			getSortingExcel(data) {
				this.sortingSkuLink = data;
				if (data != '') {
					let str = data.split("~");
					this.sorttingFileTXTName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			// 获取上传的正式报关文件
			getCustomsDeclaration(data){
				this.createForm.customsDeclarationFileUrlList = [];
				if(data.length > 0){
					data.forEach(item => {
						this.createForm.customsDeclarationFileUrlList.push(item.url);
					})
				}
			},
			// 下载空运清单模板
			handleDownloadAirTemplate() {
				let url = '';
				switch (this.createForm.warehouseType) {
					case '1':
						url = this.airTemplate.fba;
						break
					case '2':
						url = this.airTemplate.noon;
						break;
					case '5':
						url = this.airTemplate.owh;
						break;
				}
				window.open(url);
			},
			
			//获取品名列表
			async getKindNameList() {
				const res = await this.$http.get("/toborder/productname/list");
				if (res.code == 200) {
					if (res.data.length >= 0) {
						res.data.forEach((item, index) => {
							this.kindNameList.push({
								id: item.id,
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
						})
						this.kindNameList = this.quchong(this.kindNameList);
					}
				}
			},
			closeDialog() {
				this.kindNamePopup = false;
			},
			openKindName() {
				this.addKindNameList = [];
				this.addItem();
				this.kindNamePopup = true;
			},
			// 动态添加一行
			addItem() {
				this.addKindNameList.push({
					productChineseDesc: "",
					productEnglishDesc: "",
				});
			},
			// 其他杂费删除一行
			delItem(index) {
				this.addKindNameList.splice(index, 1);
			},
			//新增品名
			submitNewKindName() {
			
				let isEmpty = false;
				let kindNameData = [];
				this.addKindNameList.forEach((item, index) => {
					if (item.productChineseDesc != '' && item.productEnglishDesc != '') {
						kindNameData.push(item);
					}
					if (item.productChineseDesc != '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc != '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
				})
				if (isEmpty) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.dialogAddKindnameSubmitTips'));
				}
				this.addKindNameLoading = true;
				this.$http.post("/toborder/productname/add", kindNameData).then(res => {
					if (res.code == 200) {
						kindNameData.forEach((item, index) => {
							this.kindNameList.push({
								id: index + '_add',
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
							this.kindName.push(item.productChineseDesc);
						})
						this.addKindNameLoading = false;
						this.$message.success(this.$t('tipsInfo.success'));
						this.kindNamePopup = false;
					} else {
						this.addKindNameLoading = false;
						this.$message.error(this.$t('tipsInfo.failed'));
					}
				}).catch(err => {
					this.addKindNameLoading = false;
				})
			},
			
			
			
			//获取订单信息
			async getOrderInfo(id) {
				await this.$http.get("/toborder/detail/" + id).then(res => {
					if (res.code == 200) {
						this.createForm.destCountry = res.data.toBOrderInfo.destCountry;
						this.createForm.destCountryId = res.data.toBOrderInfo.destCountryId;
						//级联关系，定时赋值 需要按顺序赋值
						let that = this;
						setTimeout(function() {
							that.createForm.warehouseType = res.data.toBOrderInfo.warehouseType;
							that.createForm.declaredValue = res.data.toBOrderInfo.declaredValue;
							that.createForm.skuCount = res.data.toBOrderInfo.skuCount;
							that.createForm.customsDeclarationFlag = res.data.toBOrderInfo
								.customsDeclarationFlag
							that.createForm.destCity = res.data.toBOrderInfo.destCity;
							that.createForm.clientOrderNo = res.data.toBOrderInfo.clientOrderNo;
							//预选品名
							if (res.data.toBOrderInfo.productChineseDesc != null) {
								if (res.data.toBOrderInfo.productChineseDesc.split(',').length != 0) {
									that.kindName = res.data.toBOrderInfo.productChineseDesc.split(
										',');
								}
							}
							setTimeout(function() {
								that.createForm.deliveryType = res.data.toBOrderInfo.deliveryType;
								setTimeout(function() {
									that.createForm.destWhNo = res.data.destWhNo === '' ?
										'custom' : res
										.data.destWhNo;
									setTimeout(function() {
										that.createForm.otherDest = res.data
											.toBOrderInfo.otherDest;
										setTimeout(function() {
											that.setDetail(res.data
												.lgPackageList);
										}, 500)
										if (res.data.toBOrderInfo
											.skuListLink != null && res.data
											.toBOrderInfo.skuListLink != ''
										) { //获取清单附件名
											let str = res.data.toBOrderInfo
												.skuListLink.split("~");
											that.skuListLink = res.data
												.toBOrderInfo.skuListLink;
											that.fileTXTName = [{
												name: str[1],
												url: res.data
													.toBOrderInfo
													.skuListLink,
											}];
											// 分拣清单
											if (res.data.toBOrderInfo
												.sortOrderAddress != null &&
												res.data.toBOrderInfo
												.sortOrderAddress != '') {
												let fenjian = res.data
													.toBOrderInfo
													.sortOrderAddress.split(
														"~");
												that.sortingSkuLink = res.data
													.toBOrderInfo
													.sortOrderAddress;
												that.sorttingFileTXTName = [{
													name: fenjian[1],
													url: res.data
														.toBOrderInfo
														.sortOrderAddress,
												}];
												that.isSorting = true;
											}
										}
										if (that.createForm.warehouseType == '3' ||
											that.createForm
											.warehouseType == '4' || that.createForm
											.warehouseType == '5') {
											that.createForm.contacts = res.data
												.toBOrderInfo
												.contacts;
											that.createForm.contactsTel = res.data
												.toBOrderInfo
												.contactsTel;
											that.loading = false;
										}
			
										if (that.createForm.warehouseType == '2') {
											that.createForm.asnNo = res.data
												.toBOrderInfo.asnNo;
											that.createForm.deliveryDate = res.data
												.toBOrderInfo.deliveryTime;
											that.pdfLink = res.data
												.tobOrderAttachmentInfoEntity
												.pdf;
											//修改upload的preview预览
											if (that.pdfLink != '') { //清楚附件时
												//修改upload的preview预览
												let str = res.data
													.tobOrderAttachmentInfoEntity
													.pdf
													.split("~");
												that.pdfPreName = [{
													name: str[str
														.length - 1
													],
													url: res.data
														.tobOrderAttachmentInfoEntity
														.pdf,
												}]
											}
											that.loading = false;
										} else if (that.createForm.warehouseType ==
											'1') {
											that.createForm.fbaNo = res.data
												.toBOrderInfo.fbaNo;
											that.createForm.fbaNoCode = res.data
												.toBOrderInfo
												.fbaNoCode;
											that.fbxLink = res.data
												.tobOrderAttachmentInfoEntity
												.attachment;
											if (that.fbxLink != '') {
												//修改upload的preview预览
												let str = that.fbxLink.split(
													"~");
												that.fbxPreName = [{
													name: str[str
														.length - 1
													],
													url: that.fbxLink,
												}]
											}
											that.loading = false;
										} else if (that.createForm.warehouseType ==
											'6') {
											that.uploadSuccess = true;
										}
									}, 400)
								}, 400)
							}, 400)
						}, 400);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//限制输入数字
			handleNum() {
				if (this.createForm.warehouseType == '1' && this.createForm.fbaNo == '') {
					this.caseNum = '';
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.createClientPackageNoInputFBATips'));
				}
				if (this.createForm.warehouseType == '2' && this.createForm.asnNo == '') {
					this.caseNum = '';
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.createClientPackageNoInputASNTips'));
				}
				this.caseNum = (this.caseNum.toString()).replace(/[^\d]/g, '');
				//防抖
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				if (this.caseNum != '') {
					this.timeout = setTimeout(() => {
						this.caseData = [];
						this.caseDataCustom = [];
						this.getCaseNum(this.caseNum, '');
					}, 800);
				}
			},
			// 修改循环添加 table
			setDetail(OrderCaseList) {
				let that = this;
				that.caseData = [];
				that.caseNum = OrderCaseList.length;
				if (OrderCaseList != null) {
					if (OrderCaseList.length > 0) {
						that.isCaseNormal = true;
						OrderCaseList.forEach((item, i) => {
							that.caseData.push({
								index: i,
								input: item.clientLgPackageNo,
								order: item.esnadLgPackageNo,
								isDetail: true
							});
						});
					} else { //如果是空的箱集合，则根据输入的箱数自动填充箱号
						that.caseLoading = false;
						that.getCaseNum(1, '');
					}
				} else { //如果是空的箱集合，则根据输入的箱数自动填充箱号
					that.caseLoading = false;
					that.getCaseNum(1, '');
				}
			
			},
			//amazon:填写FBA号：FBA号U001，未填写FBA号：FBX-年月日订单号后四位-001
			//noon:需要判断是否已经填写ASN号，填写：asn号-001，未填写：NOON-年月日订单号后四位-001
			//自定义海外仓：CUSTOM-年月日订单号后四位-001
			getCaseNum(caseNum, addNum) {
				this.caseLoading = true;
				if (this.optType == 'add') {
					this.isCaseNormal = false;
					this.isCaseSpecial = false;
				}
			
				if (caseNum <= 0) {
					caseNum = 1;
				}
				let nowTime = new Date();
				let caseTime = nowTime.getFullYear().toString() + (nowTime.getMonth() + 1).toString() + nowTime.getDate()
					.toString() + nowTime.getHours().toString() + nowTime.getMinutes().toString() + nowTime.getSeconds()
					.toString();
				for (let i = 0; i < caseNum; i++) {
					let num = '';
					if (addNum == '') {
						num = ('00' + (i + 1)).substr(-3);
					} else {
						num = addNum;
					}
					switch (this.createForm.warehouseType) {
						case '1':
							// FBA 默认
							this.isCaseNormal = true;
							this.caseData.push({
								input: this.createForm.fbaNo + 'U000' + num,
							});
							break;
						case '2':
							this.isCaseNormal = true;
							this.caseData.push({
								input: this.createForm.asnNo + '-' + num,
							});
							break;
						case '5':
							if (this.isSpecial == true) {
								this.isCaseSpecial = true;
								this.caseDataCustom.push({
									preNum: this.prefixAwbNo,
									input: this.createForm.clientOrderNo,
									nextNum: this.createForm.orderNo.substr(-4) + '-' + num,
									isNull: false,
									color: 'white',
								})
							} else {
								this.isCaseNormal = true;
								this.caseData.push({
									input: this.prefixAwbNo + this.clientEnName + this.createForm.orderNo.substr(-4) +
										'-' +
										num,
								});
							}
							break;
						case '6': // 转单小包生成箱号规则与海外仓一样
							if (this.isSpecial == true) {
								this.isCaseSpecial = true;
								this.caseDataCustom.push({
									preNum: this.prefixAwbNo,
									input: '',
									nextNum: this.createForm.orderNo.substr(-4) + '-' + num,
								})
							} else {
								this.isCaseNormal = true;
								this.caseData.push({
									input: this.prefixAwbNo + this.clientEnName + this.createForm.orderNo.substr(-4) +
										'-' +
										num,
								});
							}
							break;
					}
				}
				let that = this;
				setTimeout(function() {
					//更新显示箱数
					if (that.isCaseSpecial) {
						that.caseNum = that.caseDataCustom.length;
					}
					if (that.isCaseNormal) {
						that.caseNum = that.caseData.length;
					}
				}, 100)
			
				this.caseLoading = false;
			},
			
			//添加一条箱号数据
			addCaseItem(item) {
				let num1 = item.input.substr(-3);
				if (this.optType == "edit" && this.caseDataCustom.length > 0) { //修改时再增加箱数
					num1 = this.caseDataCustom[this.caseDataCustom.length - 1].nextNum.split('-')[1];
				}
				let num2 = ('00' + (parseInt(num1) + 1)).substr(-3);
				this.getCaseNum(1, num2)
			},
			// 增加custom箱号数据
			addCustomCaseItem(item) {
				let num = ('00' + (parseInt(item.nextNum.split('-')[1]) + 1)).substr(-3);
				this.caseDataCustom.push({
					preNum: item.preNum,
					input: '',
					nextNum: item.nextNum.split('-')[0] + '-' + num,
					isNull: false,
					color: 'white',
				})
			},
			//删除选择的箱号数据
			delCaseItem(row, index) {
				console.log(row);
				let that = this;
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							that.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: that.createForm.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let indexs = that.caseData.findIndex(
											item => item.index === row.index
										);
										console.log(indexs);
										that.caseData.splice(indexs, 1);
										that.caseNum--;
										that.$message.success(that.$t('tipsInfo.deleteSuccessTips'));
									} else {
										that.$message.error(res.msg);
									}
									if (that.caseData.length == 0) {
										that.getCaseNum(1, '');
									}
								});
						} else {
							that.caseData.splice(index, 1);
							that.caseNum--;
							that.$message.success(that.$t('tipsInfo.deleteSuccessTips'));
							if (that.caseData.length == 0) {
								that.getCaseNum(1, '');
							}
						}
					})
					.catch(() => {});
			},
			//删除选择的箱号custom数据
			delCaseCustomItem(row, index) {
				let that = this;
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							that.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: that.createForm.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let customIndex = that.caseDataCustom.findIndex(
											item => item.index === row.index
										);
										that.caseDataCustom.splice(customIndex, 1);
										that.caseNum--;
										that.$message.success(that.$t('tipsInfo.deleteSuccessTips'));
									} else {
										that.$message.error(res.msg);
									}
									if (that.caseData.length == 0 && that.caseDataCustom.length == 0) {
										that.getCaseNum(1, '');
									}
								});
						} else {
							that.caseDataCustom.splice(index, 1);
							that.caseNum--;
							that.$message.success(that.$t('tipsInfo.deleteSuccessTips'));
							if (that.caseData.length == 0 && that.caseDataCustom.length == 0) {
								that.getCaseNum(1, '');
							}
						}
					})
					.catch(() => {});
			},
			
			// 自动填充该箱填写信息到后续箱信息
			autoFillCaseInfo(data, i) {
				let info = this.caseDataCustom[i].input;
				this.caseDataCustom.forEach((item, index) => {
					if (index >= i) {
						item.input = info;
					}
				})
				this.handleInputCustomCase();
			},
			// 监听自定义箱号输入信息
			handleInputCustomCase() {
				// 点击提交后才监听每一次的输入是否正确
				if (this.isHandleSubmit) {
					this.handleCheckCaseInfo(this.caseDataCustom);
				}
			
			},
			
			// 检测箱信息填写是否完整
			handleCheckCaseInfo(data) {
				let isAllRight = true;
				data.forEach((item, index) => {
					if (item.input == '') {
						item.color = '#e3cd85';
						isAllRight = false;
					} else {
						item.color = 'white';
					}
					this.$set(this.caseDataCustom, index, item);
				})
				return isAllRight;
			},
			// 检验当前订单号是否最新，防止客户打开两个浏览器且都打开下单窗口，都提交导致一个订单号出现两个面单情况
			async checkIsLastOrderNo() {
				this.submitLoading = true;
				if(this.optType == 'add'){
					let currentOrderNo = this.createForm.orderNo;
					// 获取当前最新订单号
					const res = await this.$http.post("/toborder/create/0");
					if (res.code === 200) {
						this.createForm.orderNo = res.data;
						// 订单号对不上，需要更新数据
						if(currentOrderNo != this.createForm.orderNo){
							// 为海外仓需要更新箱号
							if(this.createForm.warehouseType == '5'){
								// 自定义箱号
								if(this.isSpecial == true){
									this.caseDataCustom.forEach(item => {
										let str = this.createForm.orderNo.substr(-4);
										let num = item.nextNum.split('-')[1];
										item.nextNum = str + '-' + num;
									})
								}else{
									this.caseData.forEach(item => {
										let str = this.prefixAwbNo + this.clientEnName + this.createForm.orderNo.substr(-4);
										let arr = item.input.split('-');
										let num = arr[arr.length - 1];
										item.input = str + num;
									})
								}
							}
						}
						this.submitUpdateInfo();
					} else {
						this.submitLoading = false;
						this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
					}
				}else{
					this.submitUpdateInfo();
				}
			},
			
			submitUpdateInfo() {
				if (this.createForm.warehouseType == '1') { //必须上传FBX附件
					if (this.fbxLink == '' || this.fbxLink == null) {
						this.$message.warning(this.$t('tipsInfo.submitOrderFBXFileTips'));
						this.submitLoading = false;
						return;
					}
				}
				if (this.createForm.warehouseType == '2') { //如果是noon仓库则必须上传PDF文件
					if (this.pdfLink == '' || this.pdfLink == null) {
						this.$message.warning(this.$t('tipsInfo.submitOrderPDFFileTips'));
						this.submitLoading = false;
						return;
					}
				}
				if (this.createForm.warehouseType == '6') { // 转单需要附件信息
					if (this.uploadData.length == 0) {
						this.$message.warning(this.$t('tipsInfo.submitOrderPacketFileTips'));
						this.submitLoading = false;
						return;
					}
				}
			
			
			
				let that = this;
				//第二个接口参数
				let obj = {
					clientLgPackageList: [],
					esnadTobOrderNo: this.createForm.orderNo,
					clientOrderNo: this.createForm.clientOrderNo,
				};
				let productList = [];
				//处理品名字段
				this.formData.productChineseDesc = '';
				this.formData.productEnglishDesc = '';
				//是否上传清单 
				if (this.skuListLink == '' && this.createForm.warehouseType != '6') { //转单小包不需要上传清单
				  this.submitLoading = false;
					return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
				} else {
					obj.skuListLink = this.skuListLink;
				}
				//分拣送货清单附件链接 非必填项
				obj.sortOrderAddress = this.sortingSkuLink;
				this.formData.skuCount = this.createForm.skuCount;
				this.formData.declaredValue = this.createForm.declaredValue;
				this.formData.customsDeclarationFlag = this.createForm.customsDeclarationFlag;
				if (this.kindName.length != 0) {
					this.kindName.forEach(item => {
						that.kindNameList.forEach(item1 => {
							if (item1.productChineseDesc == item) {
								that.formData.productChineseDesc += item1.productChineseDesc + ',';
								that.formData.productEnglishDesc += item1.productEnglishDesc + ',';
							}
						})
					})
				} else if (this.createForm.warehouseType == '6') {
					this.formData.productChineseDesc = '';
					this.formData.productEnglishDesc = '';
				} else {
					this.submitLoading = false;
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderProductionTips'))
				}
			
				this.formData.productChineseDesc = this.formData.productChineseDesc.substr(0, this.formData
					.productChineseDesc.length - 1);
				this.formData.productEnglishDesc = this.formData.productEnglishDesc.substr(0, this.formData
					.productEnglishDesc.length - 1);
				if (this.createForm.skuCount <= 0 || this.createForm.declaredValue <= 0) {
					if (this.createForm.warehouseType == '6') {
						this.submitLoading = false;
						return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputPackageNumTips'))
					} else {
						this.submitLoading = false;
						return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputSkuNumberTips'))
					}
				}
			
			
				//调用接口
				//上传订单信息
				if (this.isCaseNormal) {
					if (this.caseData.length > 0) {
						this.caseData.forEach(item => {
							if (item.input.length !== 0) {
								obj.clientLgPackageList.push(item.input);
							}
						});
					}
				}
				this.isHandleSubmit = true; //验证显示
				if (this.isCaseSpecial) {
					if (this.caseDataCustom.length > 0) {
						// 特殊箱信息需要验证客户是否填写正确
						if (this.handleCheckCaseInfo(this.caseDataCustom)) {
							this.caseDataCustom.forEach(item => {
								obj.clientLgPackageList.push(item.preNum + item.input + item.nextNum);
							})
						} else {
							this.submitLoading = false;
							return this.$message.warning(this.$t('chinaWhB2BOrderPage.caseInputTips'))
						}
			
					}
				}
			
				this.orderCaseNum = obj.clientLgPackageList.length;
				if (this.orderCaseNum <= 0) {
					this.submitLoading = false;
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputClientPackageNumTips'))
				}
			
				obj = Object.assign(obj, this.formData);
			
				if (this.createForm.destCountry == 'SA') {
					if (this.createForm.warehouseType == '5' && this.createForm.deliveryType == '2') {
						this.createForm.destWhNo = this.SelfDestWhNo;
						this.createForm.otherDest = this.SelfOtherDest;
					}
					if (this.createForm.warehouseType == '6') {
						this.createForm.destWhNo = this.SelfDestWhNo;
						this.createForm.otherDest = this.SelfOtherDest;
						this.createForm.execllist = this.uploadData;
					}
				}
				//第三个接口参数
				// 2022-5-17 zpy 地址路径需要编码防止乱码 
				let fileObj = {
					orderNo: this.createForm.orderNo,
					attachment: this.fbxLink, //FBX文件路径
					pdf: this.pdfLink, //PDF文件路径
				}
				this.$refs["createForm"].validate(valid => {
					if (valid) {
						// this.submitLoading = true;
						let formParams = JSON.parse(JSON.stringify(this.createForm));
						if (formParams.destWhNo == 'custom') { //如果选择自定义海外仓，则仓库传空值，后台判断仓库和仓库地址只取一个值，所以两个一个要设置为空
							formParams.destWhNo = '';
						}
						this.$http.post("/toborder/update/dest", formParams).then(res => {
							if (res.code == 200) {
								that.$http.post("/toborder/update/lgPackageInfo", obj).then(resTwo => {
									if (resTwo.code === 200) {
										//上传附件信息
										that.$http.post("/toborder/attachment/info/add", fileObj)
											.then(
												resThree => {
													if (resThree.code == 200) {
														that.$message.success(this.$t(
															'tipsInfo.operationSuccess'))
														that.submitLoading = false;
														that.popup = false;
														that.clearInfo();
														that.$parent.getlist();
														//显示详情
														that.showOrderDetail();
													} else {
														that.submitLoading = false;
														that.$message.error(resThree.msg);
													}
												})
									} else {
										that.submitLoading = false;
										that.$message.error(resTwo.msg);
									}
								})
							} else {
								that.submitLoading = false;
								that.$message.error(res.msg);
							}
						})
					}else{
						that.submitLoading = false;
					}
				})
			},
			
			showOrderDetail() {
				this.detailPopup = true;
				this.detail_orderNo = this.createForm.orderNo;
				this.detail_declaredValue = this.createForm.declaredValue;
				this.detail_destCountry = this.createForm.destCountry;
				if (this.createForm.destCountry == 'SA' && this.createForm.deliveryType == '2' && this.createForm.warehouseType == '5') {
					this.detail_destWhNo = this.SelfDestWhNo;
					this.detail_otherDest = this.SelfOtherDest;
				} else {
					this.detail_destWhNo = this.createForm.destWhNo;
					this.detail_otherDest = this.createForm.otherDest;
				}
			
				// 生成下载面单
				this.expressSheet();
				this.getAddress();
			},
			// 下载PDF面单
			expressSheet() {
				let that = this;
				this.PdfLoading = true;
				this.$http.get(
					"/toborder/download/waybill/" + this.createForm.orderNo
				).then(res => {
					if (res.code === 200) {
						this.pdfUrl = res.data;
					} else {}
					that.PdfLoading = false;
				}).catch(err => {
					if (err.code != 500) {
						var waitMinute = Math.round(this.orderCaseNum / 60);
						this.createErrorInfo = this.$t('chinaWhB2BOrderPage.generateBoxStickerTipsPre') +
							waitMinute + '-' + (waitMinute + 1) + this.$t(
								'chinaWhB2BOrderPage.generateBoxStickerTipsNext');
					} else {
						this.$message.warning(err.msg);
					}
			
				})
			},
			// 下载paf
			getPdf() {
				window.open(this.pdfUrl, "_blank");
			},
			//获取仓库地址
			getAddress() {
				this.$http.get("/toborder/system/dict/data/list?dictType=sys_szwh_address").then(res => {
					if (res.code == 200) {
						this.whAddress = res.rows[0].dictValue;
						this.whContactMan = res.rows[0].remark.split('###')[0];
						this.whContactPhone = res.rows[0].remark.split('###')[1];
					} else {
						this.$message.error("获取仓库地址失败");
					}
				})
			},
			handleCloseDetail() {
				this.detailPopup = false;
				this.pdfUrl = '';
				this.createErrorInfo = '';
				this.kindName = [];
				this.showType = false; //隐藏派送方式
				this.pdfUrl = '';
				this.createForm = {
				  destCountry: '', //国家
				  destCity: '', //城市
				  destCountryId: 2,
				  warehouseType: '',
				  destWhNo: '', //仓库
				  otherDest: '', //地址
				  fbaNo: '', //FBA号
				  fbaNoCode: '', //FBA跟踪编码
				  asnNo: '', //noon流程的预约号
				  deliveryDate: '', //预约送仓时间
				  orderNo: '', //订单编号
				  contacts: '', //自定义海外仓 联系人
				  contactsTel: '', //自定义海外仓 联系电话
				  declaredValue: 0, //申报金额
				  skuCount: 0, //SKU总数
				  deliveryType: '', //派送方式，1送货上门，2仓库自提
				  execllist: [], //转单小包信息
				  customsDeclarationFlag: false, //是否报关退税 默认false
				  clientOrderNo: '', //客户订单号，必填
					clientOperationType:'',//订单类型（客户清关方式）： 1、快递 2、Cargo
				};
				this.$parent.rowData = {};
				this.$parent.optType = '';
				this.$parent.createOrderPopup = false;
				// 关闭更新列表，同步生成的面单链接
				this.$parent.getlist();
			},
    },
    created() {
    },

  }
</script>

<style scoped>
  .stepthrees {
  	height: 100%;
  	line-height: 45px;
  }
  
  .three>span {
  	font-size: 16px;
  }
  
  .stepthree {
  	padding: 20px;
  }
  
  .orderDetails {
  	margin-bottom: 10px;
  	font-size: 20px;
  }
  
  .el-divider--horizontal {
  	margin: 30px 0 !important;
  }
  
  .stepthree div>span {
  	display: inline-block;
  	width: 100%;
  	text-align: left;
  }
	.fileUploadBtn {
		float: left;
		margin: 0 10px;
		max-width: 220px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.productRow{
			::v-deep .el-col{
				float: right;
			}
			::v-deep .el-form-item__label {
				float: right;
			}
			::v-deep .el-form-item__content {
				margin-left: 0px !important;
				margin-right: 180px;
			}
		}
		
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
		}
		.fileUploadBtn{
			float: right;
			margin: 0 10px;
			max-width: 220px;
		}
		.stepthree div>span {
			text-align: right;
		}
	}
</style>
